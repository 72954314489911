@mixin settings-threshold-component() {
  .container {
    margin: 31px 10px 0px 0px;

    .page-title {
      text-align: center;
      margin: 0px auto;
    }

    .page-description {
      margin: 24px auto 40px 27px;
      padding-right: 2px;
    }

    .category-section {
      padding: 0px 0px 0px 16px;
    }
  }
}

@mixin settings-custom-expansion-panel-component() {
  mat-expansion-panel {
    mat-expansion-panel-header.mat-expansion-panel-header {
      .mat-expansion-indicator {
        position: relative;
        top: -18px;
      }
    }
  }
}

@mixin apply-moblie() {
  @include settings-threshold-component();
  mat-accordion {
    app-event {
      app-custom-expansion-panel {
        @include settings-custom-expansion-panel-component();
      }
    }
  }
}
